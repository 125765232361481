<template>
  <div>
    <div>
      <v-btn :to="'/pois' + apiString">Zur POI-Liste</v-btn>
    </div>
    <div>
      <v-btn :to="'/teaser' + apiString">Zur Teaser Übersicht</v-btn>
    </div>
    <div>
      <v-btn :to="'/summary' + apiString">Zur Summary</v-btn>
    </div>
  </div>
</template>
<script>
import { apiString } from "@/helpers/detailViewHelper";

export default {
  props: {
    apiKey: String
  },
  computed: {
    apiString
  }
};
</script>
